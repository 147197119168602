import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import http from "./axios"

Vue.use(Router);

const router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/desktop',
            name: 'desktop',
            component: () => import('./views/Desktop'),
            meta: {
                title: 'Desktop',
                withoutToken: true
            }
        },
        {
            path: '/sign',
            name: 'SignList',
            component: () => import('./views/sign/list'),
            meta: {
                title: 'SignList',
                permission: 'SIGN'
            }
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('./views/user/UserList'),
            meta: {
                title: 'UserList',
                permission: 'USER'
            }
        },
        {
            path: '/user/new',
            name: 'user-add',
            component: () => import('./views/user/UserEdit'),
            meta: {
                title: 'UserAdd',
                permission: 'USER'
            }
        },
        {
            path: '/user/:id',
            name: 'user-add',
            component: () => import('./views/user/UserEdit'),
            meta: {
                title: 'UserAdd',
                permission: 'USER'
            }
        },
        {
            path: '/sign/:code',
            name: 'sign',
            component: () => import('./views/sign/index'),
            meta: {
                title: 'Sign',
                permission: 'SIGN'
            }
        },
        {
            path: '/sign/user-registration/:code',
            name: 'user-registration',
            component: () => import('./views/sign/user-registration'),
            meta: {
                title: 'User registration',
                withoutToken: true
            }
        },
        {
            path: '/kafka-events',
            name: 'Kafka event list',
            component: () => import('./views/kafka/list'),
            meta: {
                title: 'KafkaList',
                permission: 'KAFKA_EVENTS'
            }
        },
        {
            path: '/sign/view/:id',
            name: 'SignView',
            component: () => import('./views/sign/view'),
            meta: {
                title: 'Sign View',
                permission: 'SIGN'
            }
        },
        {
            path: '/test/:type',
            name: 'test',
            component: () => import('./views/sign/test'),
            meta: {
                title: 'Test',
                withoutToken: true
            }
        },
        {
            path: '/qr-code/:code',
            name: 'qr-code',
            component: () => import('./views/qr-code'),
            meta: {
                title: 'QR kod',
                withoutToken: true
            }
        },
        {
            path: '/check-key',
            name: 'check-key',
            component: () => import('./views/check-key'),
            meta: {
                title: 'Test',
                permission: 'CHECK_KEY'
            }
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});


router.beforeEach((to, from, next) => {
    if (!to.meta.withoutToken && !localStorage.getItem('vue-token')) {
        store.dispatch('keycloak').then()
        return ;
    } else {
        if (to.meta.withoutToken){
            store.dispatch('app').then()
        }
    }

    store.commit('initPermission', to.meta);
    if (!to.meta.withoutToken){
        http.get('permission/user/menu')
            .then(res => {
                store.commit('setPermission', res.data.menus)
                if (to.meta.permission && res.data.menus && res.data.menus.indexOf(to.meta.permission) >= 0) {
                    next()
                }
            }, err => {
                console.log(err)
            });
    } else {
        next()
    }

    return next;
});

export default router;